.grammata {
  color: white !important;
  font-weight: 600 !important;
  font-family: "boxicons" !important;
  line-height: 1.4 !important;
  padding-top: 3%;
  padding-bottom: 2% !important;
  padding-left: 2% !important;
}
.paragr {
  color: white !important;
  padding-left: 2% !important;
  padding-right: 2% !important;
}

.lista {
  color: white !important;
  padding-left: 2% !important;
  padding-right: 2% !important;
}
.spn {
  color: white !important;
  font-weight: 900 !important;
  font-family: "boxicons" !important;
  line-height: 1.2 !important;
}
.full {
  background: linear-gradient(-45deg, #2f325f, #164e52, #23a6d5, #137c64);
  background-size: 400% 400%;
  position: relative;
  animation: change 10s ease-in-out infinite;
}
@keyframes change {
  0% {
    background-position: 40% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
