.new-ci {
  position: relative !important;
  width: 100% !important;
  overflow: hidden !important;
  width: 512px !important;
  height: 288px !important;
}

.new-c {
  position: relative !important;
  width: 512px !important;
  height: 288px !important;
}

.home-carousel-img {
  height: 900px !important;
}

@media only screen and (max-width: 768px) {
  .home-carousel-img {
    height: 350px !important;
  }
  .new-ci {
    position: relative !important;
    overflow: hidden !important;
    width: 100% !important;
    height: 288px !important;
  }

  .carousel-item {
    height: 288px !important;
  }

  .new-c {
    position: relative !important;
    width: 100% !important;
    height: 288px !important;
  }
}
